@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* Root Variables */
:root {
    --font-primary: 'Nunito Sans', 'Open Sans', sans-serif;
    --color-background-dark: #212121;
    --color-background-dark-light: #333;
    --color-text-light: #fff;
    --color-text-highlight: #231f3d;
    --color-hover-bg: #575757;
    --color-border: #fff;
    --text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    --transition-duration: 0s;
}

/* Navigation Styles */
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    background-color: var(--color-background-dark) ;
    color: var(--color-text-light);
    position: relative;
    z-index: 1;
}
.navbar{
  padding: 18px !important;
}

nav.open {
    background-color: transparent;
}

.navbar-logo img {
    max-height: 62px;
}

.navbar-toggle {
  font-size: 4rem;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  z-index: 2;
  padding: 0px 20px;
}

.hamburger-icon,
.close-icon {
    font-size: 24px;
}

.navbar-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; 
  background-color: var(--color-background-dark-light);
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height var(--transition-duration) ease-out, opacity var(--transition-duration) ease-out;
  z-index: 1;
}

.navbar-items.open {
    max-height: 100%;
    opacity: 1;
    transition: max-height var(--transition-duration) ease-in, opacity var(--transition-duration) ease-in;
}

.navbar-items a {
    padding: 20px;
    text-decoration: none;
    color: var(--color-text-light);
    display: block;
    width: 100%;
    font-size: 2.5rem;
    font-family: "Arvo";
}

.navbar-items a.active {
    color: var(--color-text-highlight);
    font-weight: 700;
}

.navbar-items a:hover {
    background-color: var(--color-hover-bg);
    border: 1px dotted var(--color-border);
}
