@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root {
    --font-primary: 'Montserrat', sans-serif !important;
    --font-secondary: 'Arvo', serif !important;
    --color-background-dark: #1c1c1e;
    --color-background-light: #2c2c2e;
    --color-background-grey: #2d2d2d;
    --color-background-lightest: #3e3e3e;
    --color-background-darkest: #212121;
    --color-text-light: #e5e5ea;
    --color-text-white: #f2f2f7;
    --color-border: #636366;
    --color-highlight: #0a84ff;
    --color-shadow: rgba(28, 28, 30, 0.5);
    --transition-duration: 0.3s;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-primary);
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    background-color: var(--color-background-dark);
    color: var(--color-text-light);
}

/* Image Container Styles */
.image-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Centered Text Styles */
.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: var(--color-text-light);
    font-size: 10rem;
    font-weight: 700;
    font-family: var(--font-primary);
    text-shadow: var(--color-shadow);

}

.work-text {
    font-size: 5rem !important;
}

.glow-effect {
    color: #ffffff;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Roster Page */
.roster {
    background-color: var(--color-background-grey);
    line-height: 0.6;
    padding-bottom: 70px;
    font-family: var(--font-primary);
}

.roster h2 {
    font-size: 2.3rem;
    font-weight: 400;
    letter-spacing: 0.8px;
    font-family: var(--font-primary);
}

.roster-para {
    font-weight: 500;
    color: var(--color-text-light);
    font-size: 1.2rem;
    font-family: var(--font-primary);
}

.container-fluid {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-background-lightest);
}

.row {
    justify-content: center;
    --bs-gutter-x: 0rem !important;
}

.card {
    width: 100%;
    max-width: 300px;
    border: none !important;
    transition: transform var(--transition-duration) ease, box-shadow var(--transition-duration) ease;
    border: 1px solid var(--color-border);
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px var(--color-shadow);
    filter: brightness(1.1);
}

.card-img-top {
    border-radius: 0 !important;
}

.card-body {
    background-color: var(--color-background-lightest);
}

.card-text {
    font-size: 1.7rem;
    text-align: center;
}


/* Media Page */
.container {
    --bs-gutter-x: 0rem !important;
}

.hero-section {
    background-color: var(--color-background-dark);
}

.hero-section h3 {
    font-family: var(--font-primary);
    color: var(--color-text-white);
    line-height: 2;
    font-size: 5rem;
}

.hero-section p {
    font-family: var(--font-secondary);
    color: var(--color-text-light);
    line-height: 2;
}

.hero-section .btn {
    font-family: var(--font-secondary);
    color: black;
    padding: 15px 25px;
    border-radius: 4px;
    font-size: 1.2rem;
    border-color: var(--color-border);
}

.hero-section .btn:hover {
    background-color: var(--color-background-lightest);
    border: none;
    color: var(--color-text-light);
}

.second-section {
    background-color: var(--color-background-grey)
}

.package-section {
    background-color: var(--color-background-dark);
}

.Intermediate {
    background-color: var(--color-background-grey);
}


.package-section h3 {
    font-family: var(--font-primary);
    font-size: 2rem;
    color: var(--color-text-white);
}

.package-section h4 {
    font-size: 2rem;
    color: var(--color-text-white);
}

.package-section p {
    font-family: var(--font-secondary);
    color: var(--color-text-light);
    line-height: 2;
    font-size: 1.4rem;
}

.package-section .btn {
    font-family: var(--font-secondary) !important;
    color: black;
    padding: 15px 25px;
    border-radius: 4px;
    font-size: 1.2rem;
    border-color: var(--color-border);
    margin-top: 20px;
}

.package-section .btn:hover {
    background-color: var(--color-background-lightest);
    border: none;
    color: var(--color-text-light);
}

.roster {
    background-color: var(--color-background-grey);
}

.package-img {
    max-width: 80%;
}

.package-text {
    line-height: 1 !important;
    font-weight: 400;
}

.media-text h4 {
    font-size: 1.2rem;
}

.basic {
    font-size: 3rem !important;
}

/* Footer */
.box {
    background-color: #212121;
    padding: 42px;
}

.box2 {
    background-color: #1b1b1b;
    padding: 15px;
}

@media (min-width: 992px) {
    .desktop-margin {
        margin-left: 16%;
    }
    
   
}

.list-left-align {
    text-align: left !important;
}


@media (min-width: 320px) and (max-width: 852px) {
    .navbar-logo img {
        max-height: 40px;
    }

    .hero-section .img-fluid {
        padding: 0px !important;
    }

    .btn {
        margin: 10px 0px 30px 0px;
        padding: 12 px 20px !important;
    }

    .media-text {
        padding: 30px 0px 25px 0px !important;
        text-align: center;
    }

    .media-text h3 {
        line-height: 1.3;
        font-size: 3rem;
        font-weight: 400;
    }

    .media-text p {
        font-size: 0.9rem;
    }

    .mobile-center {
        text-align: center;
    }

    .mobile-center ul {
        margin-left: 24px !important;
    }

    .custom-img {
        max-width: 100% !important;
        margin-left: 0px !important;
        padding: 0px !important;
    }

    .package-section {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .work-text {
        font-size: 4rem !important;
    }

    .package-text {
        padding: 10px 0px 0px 12px !important;
        margin-left: 0px !important;
        margin-top: 0px !important;

    }

    .package-text h3 {
        font-weight: 400;
        font-size: 1.8rem;
    }

    .package-text p {
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .package-img {
        padding: 15px !important;

    }

    .Intermediate {
        padding: 0px !important;

    }

    .intermediate-text {
        padding: 20px 0px 0px 22px !important;
        font-size: 1.5rem;
    }

    .intermediate-text h3 {
        font-weight: 400;
        font-size: 1.6rem;
    }

    .Intermediate img {
        padding: 0px !important;
    }

    .roster-para {
        line-height: 1;
    }

    .card-text {
        font-size: 1.2rem;
        line-height: 1;
    }

    .centered-text {
        font-size: 4rem;
    }


}